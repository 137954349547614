.myModal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: none;
    padding: 10px;
    left: 0;
    top: 0;
    background-color: #0000009e;
    cursor: pointer;
    z-index: 999999;
}

.myModal.active {
    display: flex;
    justify-content: center;
}

.myModalWrap {
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.myModalContent {
    cursor: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px;
    overflow: hidden;
    animation-duration: 0.2s;
    animation-name: view_popup;
}

@keyframes view_popup {
    from {
        width: 80%;
        height: 15%;
        bottom: -100px;
        position: relative;
    }
    to {
        width: 100%;
        height: 100%;
    }
}

.confirmWrap {
    cursor: auto;
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.topWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.topWrap p {
    font-size: 20px;
}

.closePopupBtn {
    border-radius: 100px;
    padding: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: none;
    color: #000000;
    border: none;
    font-size: 16px;
}

.closePopupBtn:hover {
    background-color: #cd3737;
    color: #fff;
}