.modalSmall {
    display: flex;
    flex-direction: column;
    width: min-content;
    height: min-content;
    background-color: #fbfcff;
    box-shadow: var(--default-style-shadow);
    position: absolute;
    border: 1px solid #dbdbdb;
    border-radius: 20px;
    align-items: center;
    justify-content: flex-start;
    padding: 14px;
    z-index: 9999;
}